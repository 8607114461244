/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, Image, TitleMain, Text, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Subtitle, Title, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <ColumnCover name={"menu"}>
          
          <Column className="--menu pb--02 pl--16 pr--16 pt--02" anim={null} menu={true} animS={null} style={{"backgroundColor":"rgba(255,255,255,1)"}} border={null}>
            
            <Menu className="--full pb--06 pl--06 pr--06 pt--06" style={{"maxWidth":""}} fullscreen={true}>
              
              <ColumnWrapper className="menu-logo-box mb--0 mt--0 pb--0 pr--12 pt--0" style={{"maxWidth":90}}>
                
                <Image src={"https://cdn.swbpg.com/t/11212/24c6718189954fbb818166c5d294a322_bri=114_con=110_hue=346_s=860x_fo_.png"} svg={false} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/11212/24c6718189954fbb818166c5d294a322_bri=114_con=110_hue=346_s=350x_fo_.png 350w, https://cdn.swbpg.com/t/11212/24c6718189954fbb818166c5d294a322_bri=114_con=110_hue=346_s=660x_fo_.png 660w, https://cdn.swbpg.com/t/11212/24c6718189954fbb818166c5d294a322_bri=114_con=110_hue=346_s=860x_fo_.png 860w, https://cdn.swbpg.com/t/11212/24c6718189954fbb818166c5d294a322_bri=114_con=110_hue=346_s=1400x_fo_.png 1400w, https://cdn.swbpg.com/t/11212/24c6718189954fbb818166c5d294a322_bri=114_con=110_hue=346_s=2000x_fo_.png 2000w"}>
                </Image>

              </ColumnWrapper>

              <ColumnWrapper className="menu-logo-box mt--02">
                
                <TitleMain className="title-box fs--22 w--600 pl--0" content={"<span style=\"color: rgb(0, 44, 91);\">Půjčovna minibagrů</span>"}>
                </TitleMain>

                <Text className="text-box fs--14 w--600 swpf--uppercase ls--02 lh--16 mt--0 pl--0" content={"<span style=\"color: rgb(225, 72, 47);\">Plzeň a okolí <br>tel. 724 277 450</span>"}>
                </Text>

              </ColumnWrapper>

              <MenuWrap >
                
                <MenuButton >
                </MenuButton>

                <MenuWrapBox >
                  
                  <MenuButtonClose >
                  </MenuButtonClose>

                  <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor2 fs--16 w--500 swpf--uppercase pl--06 pr--06" innerClassName="" href={"#kontakt"} tsbtn={null} target={""} content={"kontakt"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr2 btn-box--cbtn1 btn-box--sbtn1 btn-box--pbtn4 btn-box--shape5 btn-box--cColor2 fs--16 w--600 swpf--uppercase ls--01 pl--06 pr--0" innerClassName="" href={"#poptavka"} tsbtn={null} target={""} content={"Poptávka"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor2 fs--16 w--500 pl--06 pr--06" innerClassName="" href={"#sluzby-2"} tsbtn={null} target={""} content={"<br>"}>
                  </Button>

                </MenuWrapBox>

              </MenuWrap>

            </Menu>

          </Column>

        </ColumnCover>


        <Column className="css-1ifyrg7 css-42e4bw pb--0 pt--80" name={"uvod"} border={null} parallax={false} css={css`
      
    background-image: linear-gradient(rgba(21,21,40,0.8), rgba(21,21,40,0.8)), url(https://cdn.swbpg.com/t/13064/9268e474ab9c4896b05131a67b760696_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(21,21,40,0.8), rgba(21,21,40,0.8)), url(https://cdn.swbpg.com/t/13064/9268e474ab9c4896b05131a67b760696_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(21,21,40,0.8), rgba(21,21,40,0.8)), url(https://cdn.swbpg.com/t/13064/9268e474ab9c4896b05131a67b760696_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(21,21,40,0.8), rgba(21,21,40,0.8)), url(https://cdn.swbpg.com/t/13064/9268e474ab9c4896b05131a67b760696_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(21,21,40,0.8), rgba(21,21,40,0.8)), url(https://cdn.swbpg.com/t/13064/9268e474ab9c4896b05131a67b760696_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(21,21,40,0.8), rgba(21,21,40,0.8)), url(https://cdn.swbpg.com/t/13064/9268e474ab9c4896b05131a67b760696_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(21,21,40,0.8), rgba(21,21,40,0.8)), url(https://cdn.swbpg.com/t/13064/9268e474ab9c4896b05131a67b760696_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(21,21,40,0.8), rgba(21,21,40,0.8)), url(https://cdn.swbpg.com/t/13064/9268e474ab9c4896b05131a67b760696_s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 pb--40 pt--40" columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s5 --center pb--20 pt--10" anim={"7"} animS={"5"} style={{"maxWidth":1360}}>
              
              <Subtitle className="subtitle-box ff--3 fs--26 w--500 subtitle-box--invert ls--001 mt--16" content={"<br>"}>
              </Subtitle>

              <Title className="title-box fs--128 w--600 title-box--invert ls--004" content={"Půjčení bagru<br>Plzeňsko<br>"}>
              </Title>

              <Subtitle className="subtitle-box ff--3 fs--26 w--500 subtitle-box--invert ls--001 mt--16" content={"<span style=\"font-weight: bold;\"><br>Půjčíme Vám jednoduše profesionální minibagr Kubota <br>7 dnů v týdnu včetně víkendů a svátků<br></span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --shape2 --shadow4 --center el--3 pb--0 pl--40 pr--40 pt--30" anim={null} style={{"maxWidth":"","backgroundColor":"rgba(226,88,49,0.96)"}} columns={"3"} fullscreen={false}>
            
            <ColumnWrapper className="--center" anim={null} animS={null} style={{"maxWidth":360}}>
              
              <Image alt={"Ikonka půjčovna minibagru"} src={"https://cdn.swbpg.com/o/11212/b1681fd35e8b4a1cb49a5d714c5485e4.svg"} svg={false} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":96}} srcSet={""} svgColor={"rgba(255,255,255,1)"}>
              </Image>

              <Subtitle className="subtitle-box subtitle-box--invert mt--08" content={"<span style=\"font-weight: bold;\">Stroje v perfektní kondici</span>"}>
              </Subtitle>

              <Text className="text-box text-box--invert mt--12" style={{"maxWidth":""}} content={"<span style=\"color: rgb(193, 215, 235); font-weight: bold;\">Půjčujeme minibagry v top stavu , které pravidelně servisujeme.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" anim={null} animS={null} style={{"maxWidth":360}}>
              
              <Image alt={"Ikonka minibagr bez skrytých poplatků"} src={"https://cdn.swbpg.com/o/11212/e2b74a73b46c45cda6f70a09312c0cd1.svg"} svg={false} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":96}} srcSet={""} svgColor={"rgba(255,255,255,1)"}>
              </Image>

              <Subtitle className="subtitle-box subtitle-box--invert mt--08" content={"<span style=\"font-weight: bold;\">Minibagr all inclusiv</span>"}>
              </Subtitle>

              <Text className="text-box text-box--invert mt--12" style={{"maxWidth":""}} content={"<span style=\"color: rgb(193, 215, 235); font-weight: bold;\">Půjčujeme 7 dnů v týdnu včetně víkendů a svátků za bezkonkurenční cenu.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" anim={null} animS={null} style={{"maxWidth":360}}>
              
              <Image alt={"Ikonka přeprava minibagru"} src={"https://cdn.swbpg.com/o/11212/248d6af46c2d4944aa18f4ef3b95b1fd.svg"} svg={false} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":96}} srcSet={""} svgColor={"rgba(255,255,255,1)"}>
              </Image>

              <Subtitle className="subtitle-box subtitle-box--invert mt--08" content={"<span style=\"font-weight: bold;\">Vlastní přepravní<br>vlek</span>"}>
              </Subtitle>

              <Text className="text-box text-box--invert mt--12" style={{"maxWidth":""}} content={"<span style=\"color: rgb(193, 215, 235); font-weight: bold;\">S každým strojem zapůjčíme vlastní přepravní vlek – nic není potřeba překládat.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"m0jl7cvyvz"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13064/a734c0d2e4f647c497dd44d989110989_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/13064/a734c0d2e4f647c497dd44d989110989_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13064/a734c0d2e4f647c497dd44d989110989_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13064/a734c0d2e4f647c497dd44d989110989_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13064/a734c0d2e4f647c497dd44d989110989_s=1400x_.jpg 1400w"} position={null}>
              </Image>

              <Title className="title-box" content={"Kubota U10-5<br>Naklápěcí hlava &nbsp; <br>2600,- Kč/den<br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467}} content={"<span style=\"font-weight: bold; color: rgb(6, 6, 6);\">Nejoblíbenější model 1180 kg, joystickové ovládání, naklápěcí hlava, průjezd 85 cm, dosah 190 cm. Lopaty 20, 40, 80 cm</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13064/b32fbff202524b90858dc97d637e132b_s=860x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/13064/b32fbff202524b90858dc97d637e132b_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/b32fbff202524b90858dc97d637e132b_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/b32fbff202524b90858dc97d637e132b_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/13064/b32fbff202524b90858dc97d637e132b_s=1400x_.jpeg 1400w"} position={null}>
              </Image>

              <Text className="text-box" style={{"maxWidth":467}} content={"<span style=\"font-weight: bold; color: rgb(9, 9, 8);\">Základní model s páčkovým ovládáním, průjezd od 71 cm, dosah 180 cm, lopaty 20, 40, 80 cm</span>"}>
              </Text>

              <Title className="title-box" content={"Kubota K008-5 &nbsp; &nbsp;<br>2200,-Kč/den"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13064/5372bf0305084cd991b4223e770dc8a8_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/13064/5372bf0305084cd991b4223e770dc8a8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13064/5372bf0305084cd991b4223e770dc8a8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13064/5372bf0305084cd991b4223e770dc8a8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13064/5372bf0305084cd991b4223e770dc8a8_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13064/5372bf0305084cd991b4223e770dc8a8_s=2000x_.jpg 2000w"} position={null}>
              </Image>

              <Title className="title-box" content={"Minidumper<br>Cormidi 14.85 ACW &nbsp;<br>2300,-Kč/den<br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467}} content={"<span style=\"font-weight: bold; color: rgb(6, 6, 6);\">Samonakládací Minidumper s motorizací Kubota, průjezd 85 cm, nosnost 800 kg</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-iqckmp --left --parallax pb--60 pt--60" name={"9yxso1n5lt8"} layout={"l3"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/13064/7d492a3d91f04943a892756480be9d59_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13064/7d492a3d91f04943a892756480be9d59_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/13064/7d492a3d91f04943a892756480be9d59_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13064/7d492a3d91f04943a892756480be9d59_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/13064/7d492a3d91f04943a892756480be9d59_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/13064/7d492a3d91f04943a892756480be9d59_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13064/7d492a3d91f04943a892756480be9d59_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13064/7d492a3d91f04943a892756480be9d59_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--3 pb--25 pt--25" style={{"maxWidth":1470}} columns={"3"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s5" anim={"7"} animS={"5"} style={{"maxWidth":360}}>
              
              <Subtitle className="subtitle-box subtitle-box--style3" content={"Vše co potřebujete"}>
              </Subtitle>

              <Text className="text-box text-box--invert" content={"<span style=\"font-weight: bold;\">Lopaty 20 cm, 40 cm a 80 cm s rychloupínačem MS 01 &nbsp;a nájezdy 2.50 m jsou již v základní ceně půjčovného. Nic nečepujete, výměna lopaty je otázkou jedné minuty.\n\n\n<br>Vlek k bagru je zpoplatněn částkou 300,- Kč</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim7 --anim-s5" anim={"7"} animS={"5"} style={{"maxWidth":360}}>
              
              <Subtitle className="subtitle-box subtitle-box--style3" content={"All-inclusive"}>
              </Subtitle>

              <Text className="text-box text-box--invert" content={"<span style=\"font-weight: bold;\">Poskytujeme all-inclusive službu, přijedete, připojíme vlek a Vy můžete vyrazit. Nemusíte se o nic starat, vše co potřebujete k práci je součástí  připojené soupravy.\n\n\n</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim7 --anim-s5" anim={"7"} animS={"5"} style={{"maxWidth":360}}>
              
              <Subtitle className="subtitle-box subtitle-box--style3" content={"Příslušenství"}>
              </Subtitle>

              <Text className="text-box text-box--invert" content={"<span style=\"font-weight: bold;\">Půjčíme Vám i  příslušenství k bagru, které jinde nenajdete. Vrtací  jednotku, speciální lžíce, kladivo, naklápěcí hlavu apod. Vše Vám urychlí a zpříjemní práci.\n\n</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"poptavka"} style={{"backgroundColor":"rgba(0,44,91,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"backgroundColor":"rgba(252,252,253,1)"}} columns={"1"}>
            
            <ColumnWrapper className="pb--12 pt--12">
              
              <Title className="title-box title-box--invert" content={"Poptávka"}>
              </Title>

              <Text className="text-box fs--26 mt--12" style={{"maxWidth":740}} content={"<span style=\"font-weight: bold; color: rgb(7, 7, 7);\">Vyplňte následující formulář a my Vás budeme obratem kontaktovat.<br>Pokud neradi píšete volejte 724 277 450</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s5 mt--12" anim={"2"} animS={"5"}>
              
              <ContactForm className="--shape2 --shape4 --shadow5 fs--18" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno","id":"cc0js9vdoo63"},{"name":"mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail","id":"elzkks6oib8h"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420","id":"xkdrj42a1nkt"},{"name":"Požadovaná technika","type":"textarea","required":true,"placeholder":"Jakou techniku poptáváte?","id":"5qfevt5vbmdz"},{"name":"Odeslat","type":"submit","id":"b36man3wxel5"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"kontakt"} style={{"backgroundColor":"rgba(250, 251, 255, 1)"}} layout={"l1"} parallax={false}>
          
          <ColumnWrap className="column__flex el--3 pb--16 pt--10 flex--center" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"Půjčovna minibagrů"}>
              </Title>

              <Text className="text-box fs--20 w--600 swpf--uppercase ls--02 lh--16 mt--0" content={"<span style=\"color: rgb(225, 72, 47);\">Plzeň a okolí</span>"}>
              </Text>

              <Text className="text-box fs--20 w--500 mt--16" content={"<span style=\"color: rgb(0, 44, 91); font-weight: bold;\">Plzeň, Česká Republika</span><span style=\"color: rgb(0, 44, 91);\"><span style=\"font-weight: bold;\"><br>prusikv@gmail.com<br>tel. 724 277 450</span><br></span>"}>
              </Text>

              <Text className="text-box fs--13 w--500 mt--16" content={""}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mt--0">
              
              <Text className="text-box text-box--center w--500 lh--16" style={{"maxWidth":361}} content={"<span style=\"font-weight: bold; color: rgb(44, 41, 38);\">Služby nabízíme zejména pro oblasti:\nPlzeň, Příbram, Písek, Klatovy, Rokycany, Blovice, Cheb, Strakonice, České Budějovice, Tábor, Domažlice, Přeštice, Stod, Stříbro, Nýřany, Tlučná, Vejprnice, Nepomuk, Dobřany, Rokycany a další.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor2 btn-box--right fs--18 w--500 swpf--uppercase" href={"#poptavka"} content={"Poptávka"}>
              </Button>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor2 btn-box--right fs--18 w--500 swpf--uppercase" href={"#poptavka"} content={"Kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}